/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import "../../core/has.js";
import i from "../../core/Logger.js";
import r from "../../core/Queue.js";
import { property as n } from "../../core/accessorSupport/decorators/property.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { PropertiesPool as o } from "../3d/support/PropertiesPool.js";
import { primaryKey as a, isSystemModifier as h } from "./keys.js";
import { LatestPointer as l } from "./handlers/LatestPointer.js";
import { MultiTouch as p } from "./handlers/MultiTouch.js";
let d = class extends t {
  constructor(e) {
    super(e), this._pointerCaptures = new Map(), this._nameToGroup = {}, this._handlers = [], this._handlersPriority = [], this._currentPropagation = null, this._updateDependenciesAfterPropagation = !1, this._sourceEvents = new Set(), this._keyModifiers = new Set(), this._activeKeyModifiers = new Set(), this._stoppedPropagationEventIds = new Set(), this.primaryKey = a, this._latestPointerType = "mouse", this._propertiesPool = new o({
      latestPointerLocation: P
    }, this), this.latestPointerLocation = null, this._paused = !1, this.test = {
      timestamp: void 0,
      hasCurrentPropagation: () => !!this._currentPropagation
    };
  }
  initialize() {
    this.eventSource.onEventReceived = this._onEventReceived.bind(this), this._installRecognizers();
  }
  destroy() {
    const e = Object.keys(this._nameToGroup);
    for (const t of e) this.uninstallHandlers(t);
    this.eventSource.destroy(), this._currentPropagation = null, this._propertiesPool.destroy();
  }
  get hasPendingInputs() {
    return this._handlers.some(e => e.handler.hasPendingInputs);
  }
  get latestPointerType() {
    return this._latestPointerType;
  }
  get multiTouchActive() {
    return this._multiTouchHandler.multiTouchActive;
  }
  get updating() {
    return this.hasPendingInputs || this._paused;
  }
  installHandlers(e, t, i = _.INTERNAL) {
    if (this._nameToGroup[e]) return;
    if (0 === t.length) return;
    const r = {
      name: e,
      handlers: t.map(e => ({
        handler: e,
        active: !0,
        removed: !1,
        priorityIndex: 0,
        groupPriority: i,
        eventCallback: null,
        uninstallCallback: null
      }))
    };
    this._nameToGroup[e] = r;
    for (let n = r.handlers.length - 1; n >= 0; n--) {
      const e = r.handlers[n];
      this._handlers.push(e), e.handler.onInstall({
        updateDependencies: () => {
          this.updateDependencies();
        },
        emit: (t, i, r, n, s) => {
          this._emitInputEvent(e.priorityIndex + 1, t, i, r, s, n);
        },
        setPointerCapture: (t, i) => {
          this._setPointerCapture(r, e, t, i);
        },
        setEventCallback: t => {
          e.eventCallback = t;
        },
        setUninstallCallback: t => {
          e.uninstallCallback = t;
        },
        refreshHasPendingInputs: () => {
          this.notifyChange("hasPendingInputs");
        }
      });
    }
    this.updateDependencies();
  }
  uninstallHandlers(e) {
    const t = this._nameToGroup[e];
    t ? (t.handlers.forEach(e => {
      e.removed = !0, e.uninstallCallback?.();
    }), delete this._nameToGroup[e], this._currentPropagation ? this._currentPropagation.needsHandlerGarbageCollect = !0 : this._garbageCollectRemovedHandlers()) : i.getLogger(this).error("There is no InputHandler group registered under the name `" + e + "`");
  }
  hasHandlers(e) {
    return void 0 !== this._nameToGroup[e];
  }
  isModifierKeyDown(e) {
    return this._activeKeyModifiers && this._activeKeyModifiers.has(e);
  }
  updateDependencies() {
    if (this._currentPropagation) return void (this._updateDependenciesAfterPropagation = !0);
    this._updateDependenciesAfterPropagation = !1;
    const e = new Set(),
      t = new Set();
    this._handlersPriority = [];
    for (let i = this._handlers.length - 1; i >= 0; i--) {
      const e = this._handlers[i];
      e.priorityIndex = i, this._handlersPriority.push(e);
    }
    this._handlersPriority = this._sortHandlersPriority(this._handlersPriority);
    for (let i = this._handlersPriority.length - 1; i >= 0; i--) {
      const r = this._handlersPriority[i];
      r.priorityIndex = i;
      let n = r.handler.hasSideEffects;
      if (!n) for (const t of r.handler.outgoingEventTypes) if (e.has(t)) {
        n = !0;
        break;
      }
      if (n) for (const i of r.handler.incomingEventMatches) {
        e.add(i.eventType);
        for (const e of i.keyModifiers) h(e) || t.add(e);
      }
      r.active = n;
    }
    this._sourceEvents = e, this._keyModifiers = t, this._pointerCaptures.size > 0 && this._sourceEvents.add("pointer-capture-lost"), this._keyModifiers.size > 0 && (this._sourceEvents.add("key-down"), this._sourceEvents.add("key-up")), this.eventSource && (this.eventSource.activeEvents = this._sourceEvents);
  }
  _setLatestPointer(e, t, i) {
    this._latestPointerType = e;
    const r = this._get("latestPointerLocation");
    if (null == r || r.x !== t || r.y !== i) {
      const e = this._propertiesPool.get("latestPointerLocation");
      e.x = t, e.y = i, this._set("latestPointerLocation", e);
    }
  }
  _onEventReceived(e, t) {
    if ("pointer-capture-lost" === e) {
      const e = t;
      this._pointerCaptures.delete(e.native.pointerId);
    }
    this._updateKeyModifiers(e, t);
    const i = null != this.test.timestamp ? this.test.timestamp : t.native ? t.native.timestamp : void 0,
      r = t.native ? t.native.cancelable : void 0;
    this._emitInputEventFromSource(e, t, i, r);
  }
  _updateKeyModifiers(e, t) {
    if (!t) return;
    let i = !1;
    const r = () => {
        if (!i) {
          const e = new Set();
          this._activeKeyModifiers.forEach(t => {
            e.add(t);
          }), this._activeKeyModifiers = e, i = !0;
        }
      },
      n = (e, t) => {
        t && !this._activeKeyModifiers.has(e) ? (r(), this._activeKeyModifiers.add(e)) : !t && this._activeKeyModifiers.has(e) && (r(), this._activeKeyModifiers.delete(e));
      };
    if ("key-down" === e || "key-up" === e) {
      const i = t.key;
      this._keyModifiers.has(i) && n(i, "key-down" === e);
    }
    const s = t.native;
    n("Alt", !(!s || !s.altKey)), n("Control", !(!s || !s.ctrlKey)), n("Ctrl", !(!s || !s.ctrlKey)), n("Shift", !(!s || !s.shiftKey)), n("Meta", !(!s || !s.metaKey)), n("Primary", this._activeKeyModifiers.has(this.primaryKey));
  }
  _installRecognizers() {
    this._latestPointerHandler = new l((e, t, i) => this._setLatestPointer(e, t, i)), this._multiTouchHandler = new p(), this.installHandlers("input-manager-logic", [this._latestPointerHandler, this._multiTouchHandler], _.ALWAYS), this.recognizers.length > 0 && this.installHandlers("default", this.recognizers, _.INTERNAL);
  }
  _setPointerCapture(e, t, i, r) {
    const n = e.name + "-" + t.priorityIndex,
      s = this._pointerCaptures.get(i.pointerId) || new Set();
    this._pointerCaptures.set(i.pointerId, s), r ? (s.add(n), 1 === s.size && this.eventSource && this.eventSource.setPointerCapture(i, !0)) : s.has(n) && (s.delete(n), 0 === s.size && (this._pointerCaptures.delete(i.pointerId), this.eventSource && this.eventSource.setPointerCapture(i, !1)));
  }
  _garbageCollectRemovedHandlers() {
    this._handlers = this._handlers.filter(e => !e.removed), this.updateDependencies();
  }
  _emitInputEventFromSource(e, t, i, r) {
    this._emitInputEvent(0, e, t, i, r);
  }
  _emitInputEvent(e, t, i, r, n, s) {
    const o = void 0 !== r ? r : this._currentPropagation ? this._currentPropagation.timestamp : performance.now(),
      a = void 0 !== n && n,
      h = {
        event: new u(t, i, o, s || this._activeKeyModifiers, a),
        priorityIndex: e
      };
    this._currentPropagation ? this._currentPropagation.events.push(h) : this._doNewPropagation(h);
  }
  _doNewPropagation(e) {
    this._currentPropagation = {
      events: new r(),
      currentHandler: null,
      needsHandlerGarbageCollect: !1,
      timestamp: e.event.timestamp
    }, this._currentPropagation.events.push(e), this._continuePropagation();
  }
  _continuePropagation() {
    this._paused = !1;
    const e = this._currentPropagation;
    if (e) {
      for (; e.events.length > 0;) {
        const {
            event: t,
            priorityIndex: i
          } = e.events.pop(),
          r = t.data?.eventId;
        if (!(null != r && this._stoppedPropagationEventIds.has(r))) for (e.currentHandler = this._handlersPriority[i]; e.currentHandler;) {
          if (e.currentHandler.removed) e.needsHandlerGarbageCollect = !0;else {
            if (e.currentHandler.active && !t.shouldStopPropagation() && e.currentHandler.eventCallback?.(t), t.shouldStopPropagation()) {
              null != r && this._stoppedPropagationEventIds.add(r);
              break;
            }
            if (t.shouldPausePropagation(() => this._continuePropagation())) return void this._pausePropagation({
              event: t,
              priorityIndex: e.currentHandler.priorityIndex + 1
            });
          }
          e.currentHandler = this._handlersPriority[e.currentHandler.priorityIndex + 1];
        }
      }
      e.needsHandlerGarbageCollect && this._garbageCollectRemovedHandlers(), this.hasPendingInputs || this._stoppedPropagationEventIds.clear(), this._currentPropagation = null, this._updateDependenciesAfterPropagation && this.updateDependencies();
    }
  }
  _pausePropagation(e) {
    const t = new r();
    t.push(e);
    const i = this._currentPropagation;
    if (i) {
      for (; i.events.length;) t.push(i.events.pop());
      i.events = t, i.currentHandler = null, this._paused = !0;
    }
  }
  _compareHandlerPriority(e, t) {
    if (e.handler.hasSideEffects !== t.handler.hasSideEffects) return e.handler.hasSideEffects ? 1 : -1;
    if (e.groupPriority !== t.groupPriority) return e.groupPriority > t.groupPriority ? -1 : 1;
    for (const i of e.handler.incomingEventMatches) for (const e of t.handler.incomingEventMatches) {
      if (i.eventType !== e.eventType) continue;
      const t = i.keyModifiers.filter(t => e.keyModifiers.includes(t));
      if (t.length === i.keyModifiers.length !== (t.length === e.keyModifiers.length)) return i.keyModifiers.length > e.keyModifiers.length ? -1 : 1;
    }
    return e.priorityIndex > t.priorityIndex ? -1 : 1;
  }
  _sortHandlersPriority(e) {
    const t = [];
    for (const i of e) {
      let e = 0;
      for (; e < t.length && this._compareHandlerPriority(i, t[e]) >= 0;) e++;
      t.splice(e, 0, i);
    }
    return t;
  }
  get debug() {
    const e = e => {
      const t = this._setPointerCapture;
      this._setPointerCapture = () => {}, e(), this._setPointerCapture = t;
    };
    return {
      injectEvent: (t, i) => {
        e(() => {
          this._onEventReceived(t, i);
        });
      },
      disablePointerCapture: e
    };
  }
};
e([n({
  readOnly: !0
})], d.prototype, "hasPendingInputs", null), e([n({
  constructOnly: !0
})], d.prototype, "eventSource", void 0), e([n({
  constructOnly: !0
})], d.prototype, "recognizers", void 0), e([n()], d.prototype, "_latestPointerType", void 0), e([n()], d.prototype, "latestPointerType", null), e([n()], d.prototype, "multiTouchActive", null), e([n({
  readOnly: !0
})], d.prototype, "latestPointerLocation", void 0), e([n()], d.prototype, "_paused", void 0), e([n({
  readOnly: !0
})], d.prototype, "updating", null), d = e([s("esri.views.input.InputManager")], d);
class u {
  constructor(e, t, i, r, n) {
    this.type = e, this.data = t, this.timestamp = i, this.modifiers = r, this.cancelable = n, this._propagationState = c.NONE, this._resumeCallback = null;
  }
  stopPropagation() {
    this._propagationState |= c.STOPPED;
  }
  shouldStopPropagation() {
    return !!(this._propagationState & c.STOPPED);
  }
  async(e) {
    this._propagationState |= c.PAUSED;
    const t = (e, t) => {
      this._propagationState &= ~c.PAUSED;
      const i = this._resumeCallback;
      if (this._resumeCallback = null, i && i(), t) throw e;
      return e;
    };
    return ("function" == typeof e ? e() : e).then(e => t(e, !1), e => t(e, !0));
  }
  shouldPausePropagation(e) {
    return !!(this._propagationState & c.PAUSED) && (this._resumeCallback = e, !0);
  }
  preventDefault() {
    this.data.native.preventDefault();
  }
}
var c;
!function (e) {
  e[e.NONE = 0] = "NONE", e[e.STOPPED = 1] = "STOPPED", e[e.PAUSED = 2] = "PAUSED";
}(c || (c = {}));
const _ = {
  ALWAYS: 1,
  DEFAULT: 0,
  TOOL: -1,
  WIDGET: -2,
  INTERNAL: -3
};
class g {}
const P = g;
export { d as InputManager, _ as ViewEventPriorities };