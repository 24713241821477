/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { signal as t } from "../../../core/signal.js";
import { InputHandler as e } from "../InputHandler.js";
class i extends e {
  get multiTouchActive() {
    return this._multiTouchActive.value;
  }
  constructor() {
    super(!0), this._activeTouchPointerIds = new Set(), this._multiTouchActive = t(!1), this._onPointerAdd = ({
      data: t
    }) => {
      "touch" === t.pointerType && (this._activeTouchPointerIds.add(t.native.pointerId), this._update());
    }, this._onPointerRemove = ({
      data: t
    }) => {
      "touch" === t.pointerType && (this._activeTouchPointerIds.delete(t.native.pointerId), this._update());
    }, this.registerIncoming("pointer-down", this._onPointerAdd), this.registerIncoming("pointer-up", this._onPointerRemove), this.registerIncoming("pointer-capture-lost", this._onPointerRemove), this.registerIncoming("pointer-cancel", this._onPointerRemove);
  }
  _update() {
    this._multiTouchActive.value = this._activeTouchPointerIds.size > 1;
  }
}
export { i as MultiTouch };